.pathCount {
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.25rem;
  grid-template-columns: 60px 1fr;
}
.pathCount input {
  border-radius: 3px;
  border: 1px;

  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
}
