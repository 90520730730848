.container {
  box-shadow: -5px 0px 17px rgba(0, 0, 0, 0.6);

  min-width: 16rem;
  height: 100vh;
  overflow: auto;
  position: relative;
  background-color: #e2e2e2;

  padding: 1rem;

  display: grid;
  gap: 3rem;
  align-content: flex-start;
  justify-content: left;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
