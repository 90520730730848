.button {
  background-color: whitesmoke;
  border-radius: 3px;

  cursor: pointer;
  padding: 0.5rem 1rem;

  transition: all 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.selected {
  background-color: hsl(8, 76%, 63%);
  color: whitesmoke;
}
