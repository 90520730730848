.group {
  display: grid;
  grid-auto-flow: row;
  gap: 0.25rem;
}

.group h3 {
  font-weight: 600;
}

.groupItems {
  display: grid;
  grid-auto-flow: row;
  gap: 0.5rem;
}
