.colorExample {
  width: 50px;
  height: 16px;

  border-radius: 50vw;
  border: 1px solid black;

  margin: 0px 0.5rem;
}

.colorPicker {
  display: grid;
  row-gap: 0.25rem;
  justify-content: center;

  margin-top: 5px;
}
.colorPicker input {
  border-radius: 3px;
  border: 1px;
  padding: 0.5rem;

  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.openPicker {
  cursor: pointer;
  margin-left: 0.25rem;

  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}
