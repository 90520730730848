.board {
  width: 100%;
  height: 100vh;
  overflow: auto;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.board::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.boardPart {
  display: grid;
  place-items: center;
  gap: 3rem;
  padding: 3rem;
}

.colorPicker {
  display: grid;
  row-gap: 0.25rem;
}
.colorPicker input {
  border-radius: 3px;
  border: 1px;
  padding: 0.5rem;

  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
}
